import React from "react";
import Input from "../Input/Input";

function TeamMemberRegistration({
  stdNameInputName = "stdName",
  stdDOBInputName = "stdDOB",
  stdContactNumber = "stdContactNumber",
  teamCount = 1,
  disabled = false,
  stdNameInputValue,
  stdDOBInputValue,
  stdContactValue,
  handleStdNameInput,
  handleStdDOBInput,
  handleStdContactNumber,
}) {
  return (
    <div className="row   p-0">
      <span className="TeamRegistration__title">Team member {teamCount}</span>
      <div className="col-lg-4 col-md-12 col-sm-12 columnInputWrapper">
        <div className="inputWrapper">
          <label>name *</label>
          <Input
            name={stdNameInputName}
            type="text"
            placeholder="Type here"
            wrapperClasses="Registration__form__input"
            value={stdNameInputValue}
            onChange={(value) => handleStdNameInput(value, teamCount - 1)}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-12 col-sm-12 columnInputWrapper">
        <div className="inputWrapper">
          <label>Birthday *</label>
          <Input
            name={stdDOBInputName}
            type="date"
            placeholder="Type here"
            wrapperClasses="Registration__form__input"
            min={`${new Date().getFullYear() - 20}-01-01`}
            max={`${new Date().getFullYear() - 11}-12-31`}
            value={stdDOBInputValue}
            onChange={(value) => handleStdDOBInput(value, teamCount - 1)}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-12 col-sm-12 columnInputWrapper">
        <div className="inputWrapper">
          <label>Contact number *</label>
          <Input
            name={stdContactNumber}
            type="text"
            placeholder="Type here"
            wrapperClasses="Registration__form__input"
            value={stdContactValue}
            onChange={(value) => handleStdContactNumber(value, teamCount - 1)}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}

export default TeamMemberRegistration;
