import { getLastSavedRecord } from "../hooks/useFetch";

async function idGen(collectionName, idPrefix) {
  // GET LAST SAVED RECORD ID
  try {
    const { id } = await getLastSavedRecord(collectionName);

    if (id) {
      const recordId = Number(id.split(idPrefix)[1]);

      if (recordId) {
        return `${idPrefix}${String(recordId + 1).padStart(3, "0")}`;
      }
      return `${idPrefix}001`;
    }
    return `${idPrefix}001`;
  } catch (error) {
    throw error;
  }
}
export const individualIdGen = async (collectionName, idPrefix) => {
  try {
    const { id } = await getLastSavedRecord(collectionName);
    if (id) {
      const recordId = Number(id.substring(idPrefix.length));
      if (recordId) {
        return `${idPrefix}${String(recordId + 1).padStart(3, "0")}`;
      }
      return `${idPrefix}001`;
    }
    return `${idPrefix}001`;
  } catch (error) {
    throw error;
  }
};
export default idGen;
