import React from 'react'
import "./Rules.scss"
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { prabashwaraLogo } from "../../constants/asset";

function Rules() {
  return (

    <div className="container-fluid p-0 m-0 Registrations__wrapper">
      <Header theme="dark" />
      <div className="container">
        <div className="row m-0 p-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <img
              src={prabashwaraLogo}
              alt="prabashwaraLogo"
              className="Registrations__logo d-block mx-auto"
            />
            <div className="Registrations__form__wrapper p-5 mt-5 w-100">
              <h2 className="Registrations__form__title text-center">
                Rules & regulations
              </h2>
              <h3>
                General rules
              </h3>
              <p>
                <b>PRABHASHWARA</b> all island inter school media competition will be held on the <b>06th of July 2024</b> at <b>Thurstan College, Colombo 07.</b>
              </p>
              <p>
                As there will be <b>no registrations</b> on the day of the event, all contestants must register for <b>PRABHASHWARA</b> through the official registration link provided.
              </p>
              <p>All competitors should arrive at Thurstan College by 7.30 am.</p>
              <p>The opening ceremony will start from 8.30 am onwards.</p>
              <p className='remove_margin'>Below are the competition categories</p>
              <ul>
                <li>Announcing</li>
                <li>Script editing</li>
                <li>Dubbing</li>
                <li>Sport commentary</li>
                <li>Technical</li>
                <li>Cartoon drawing</li>
                <li>Graphic designing</li>
                <li>Photography</li>
                <li>Short film</li>
              </ul>

              <p className='remove_margin'>Competing age groups</p>
              <ul>
                <li>Junior - Grade 6,7,8</li>
                <li>Intermediate - Grade 9,10,11</li>
                <li>Senior - Grade 12,13</li>
              </ul>

              <p>Only students born after January 31, 2005 can participate in the competition.</p>
 
              <p>Participants in the senior category must bring their national identity card to prove their identity.</p>
 
              <p>Only two students from each school may compete in each competition category and participants in a single competition category are not permitted to compete in another.</p>
 
              <p>On the day of competition, you are responsible for bringing your own lunch. Alternatively, you can purchase food from our school canteen.</p>
 
              <p>Discipline is highly expected during the competition.</p>
 
              <p>The decisions made by the judges are final.</p>
              
              <h5 className='remove_margin'>For inquiries:</h5>
              <p>
              Tharusha Gunawardhana : <a href="tel:0769320733">0769320733</a><br />
              Esala Gunawardhana : <a href="tel:0701287670">0701287670</a><br />
              Pahan Weerasuriya : <a href="tel:0740124700">0740124700</a><br />
              Email: <a href="mailto:inquire@tcmuofficial.com">inquire@tcmuofficial.com</a>
              </p>
              <br />

              <p className='text-center'>✦•······················•✦•······················•✦</p><br />

              <h4>Announcing</h4>
              <ul>
                <li>This contest will be held in both Sinhala, English languages in <b>three age categories.</b></li>
                <li>A script will be randomly selected by the contestant and according to the script given, the contestant should present. Each contestant will be given <b>5 minutes</b> to prepare.</li>
                <li>Contestants are expected to present a News report and a News live.</li>
                <li>Contestants are expected to present a programme under the themes given to them.</li>
              </ul>

              <h4>Script editing</h4>
              <ul>
                <li>This contest will be held in both English and Sinhala languages as an <b>open category contest.</b></li>
                <li>The written test will involve creating a news article based on the given details.</li>
                <li>Contestants will receive 2 hours to complete the written test and the <b>necessary stationery items should be provided by the contestants themselves.</b> (Ex: Pens)</li>
                <li>Writing papers will be provided.</li>
              </ul>

              <h4>Dubbing</h4>
              <ul>
                <li>This contest will be held in both Sinhala, English languages as an <b>open category contest.</b></li>
                <li>A video will be randomly selected by the contestant and according to the script given, the contestant should present. Each contestant will be given 5 minutes to prepare.</li>
              </ul>

              <h4>Sports commentary</h4>
              <ul>
                <li>This contest will be held in both Sinhala, English languages as an <b>open category contest.</b></li>
                <li>Commenting in a way to create the warmth and the enthusiasm of sport within the viewers is expected in this contest. </li>
                <li>A topic must be randomly selected and a preparation period of 7 minutes will be given before the contest.  A video clip related to the sport that the contestant would select will be played.</li>
                <li>The contestant must present the commentary according to the series of sports provided (Cricket, Football and Athletics) and a sport documentary will be given.</li>
              </ul>

              <h4>Technical</h4>
              <ul>
                <li>This contest will be held in both Sinhala and English languages as an open category contest.</li>
                <li>This contest aims at testing the technical knowledge of the individuals.</li>
                <li>The contestants have to face both written and practical test.</li>
              </ul>

              <h4>Cartoon drawing</h4>
              <ul>
                <li>This contest will be held as an open category contest.</li>
                <li>Contestants will be given 2 hours to complete their work.</li>
                <li>Only A4 sheets will be provided. Contestants are responsible for providing other.</li>
                <li>Necessary stationary items themselves and Contestants can use any medium they prefer.</li>
              </ul>

              <h4>Graphic designing</h4>
              <ul>
                <li>This contest will be held as an open category contest.</li>
                <li>The contestants should create their work according to the topic given to them and the topic will be sent to a WhatsApp group.</li>
                <li>Contestants can only use Adobe Photoshop and Adobe Illustrator to create their work.</li>
                <li>You cant use any AI images and if there is any AI images used we will track them using IAITS (International Artificial Intelligence Trace System / TrACE.AI).</li>
                <li>All the contestants are supposed to submit their work before <b>6.00 pm</b> on the <b>08th of July</b> through the website.</li>
                <li>Relevant whatsapp groups will be created.</li>
                <li>Relevant submissions link will be provided.</li>
              </ul>

              <h4>Photography</h4>
              <ul>
                <li>This is an open contest.</li>
                <li>An incident captured should be uploaded to google drive and submit the details of the incident through the website.</li>
                <li>All the contestants are supposed to submit their Photograph <b>before 6.00 pm on 07th of July 2024.</b></li>
                <li>Relevant whatsapp groups will be created.</li>
                <li>Relevant submissions link will be provided.</li>
              </ul>

              <h4>Short film</h4>
              <ul>
                <li>Only one team from each school can participate in this contest.</li>
                <li>This contest will be held only in Sinhala language as an open category contest.</li>
                <li>The number of contestants participating in this contest is unlimited.</li>
                <li>The film should be designed as a short-film and staged within 8-10 minutes.</li>
                <li>The theme is “Sri Lankan Culture vs modern lifestyle”.</li>
                <li>The winning team will get the opportunity to present their short film at the Media Day.</li>
                <li>The team leader should fill the registration form with all the other team members' details.</li>
                <li>The Short film should submit <b>before 6.00 pm on 07th of July 2024</b> through the website.</li>
                <li>Relevant whatsapp groups will be created.</li>
                <li>Relevant submissions link will be provided.</li>
              </ul>

              <br />
              <p className='text-center'>✦•━━━━ • ✧ ✪ ✧ • ━━━━•✦</p><br />

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    
  );
}

export default Rules