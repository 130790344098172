import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { saveDocument } from "../../../hooks/useSave";
import { findByDocumentKey } from "../../../hooks/useFetch";
import enumValues from "../../../constants/enum";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Select from "../../../components/Select";
import Input from "../../../components/Input/Input";
import Model from "../../../components/Model/Model";
import idGen from "../../../util/idGen";
import { useNavigate } from "react-router-dom";
import { prabashwaraLogo } from "../../../constants/asset";
import "../Registrations.scss";
import dateDifferenceCounter from "../../../util/dateDifferenceCounter";
import DayCouter from "../../../components/DayCouter";
/**
 * Renders a form for school registrations.
 *
 * @return {JSX.Element} The rendered form component.
 */
function SchoolRegistrations() {
  const [modelOpen, setModelOpen] = useState(false);
  const navigate = useNavigate();
  const registrationOpenDate = "2024-06-26 15:00:00";
  const registrationCloseDate = "2024-08-15 23:59:59";
  const [formActionStatus, setFormActionStatus] = useState("pending");
  const [registrationOpenCountDown, setRegistrationOpenCountDown] = useState(
    {}
  );
  const [registrationCloseCountDown, setRegistrationCloseCountDown] = useState(
    {}
  );

  useEffect(() => {
    const intlId = setInterval(() => {
      // CALCULATE DATE DIFFERENCE
      const registrationOpenDifference =
        dateDifferenceCounter(registrationOpenDate);
      const registrationCloseDifference = dateDifferenceCounter(
        registrationCloseDate
      );
      // SET STATE  DATE DIFFERENCE
      setRegistrationOpenCountDown(registrationOpenDifference);
      setRegistrationCloseCountDown(registrationCloseDifference);
      //  CHECK IF FORM IS OPEN
      const { days, hours, minutes, seconds } = registrationOpenDifference;
      if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        setFormActionStatus("open");
      } else {
        setFormActionStatus("pending");
      }
      //  CHECK IF FORM IS CLOSE
      const {
        days: daysClose,
        hours: hoursClose,
        minutes: minutesClose,
        seconds: secondsClose,
      } = registrationCloseDifference;
      if (
        daysClose === 0 &&
        hoursClose === 0 &&
        minutesClose === 0 &&
        secondsClose === 0
      ) {
        setFormActionStatus("closed");
      }
    }, 1000);
    return () => {
      clearInterval(intlId);
    };
  }, []);

  const handleFormSubmit = async (values, formiKActions) => {
    setModelOpen(false);
    try {
      const checkSchoolRegistered = await findByDocumentKey(
        enumValues.SCHOOL_REGISTRATIONS,
        "schoolName",
        values.schoolName
      );

      if (checkSchoolRegistered) {
        toast.error("School already registered");
        return;
      }

      const newId = await idGen(
        enumValues.SCHOOL_REGISTRATIONS,
        enumValues.SCHOOL_REGISTRATION_ID_PREFIX
      );

      await saveDocument(enumValues.SCHOOL_REGISTRATIONS, {
        id: newId,
        ...values,
      });
      toast.success("Form submitted successfully");
      formiKActions.resetForm();
      navigate(`${newId}/success`);
    } catch (error) {
      toast.error("Form submission failed");
      console.error(error);
    }
  };
  const initialValues = {
    schoolName: "",
    schoolAddress: "",
    mediaUnitName: "",
    mediaUnitEmail: "",
    MicNamePrefix: "Mr.",
    MicName: "",
    MicContactNumber: "",
    PresidentNamePrefix: "Mr.",
    PresidentName: "",
    presidentWhatsApp: "",
    PresidentEmail: "",
    inviteMethod: [],
  };
  const validationSchema = Yup.object({
    schoolName: Yup.string()
      .required("This field is required.")
      .trim("should not contain leading or trailing spaces"),
    schoolAddress: Yup.string()
      .required("This field is required.")
      .trim("should not contain leading or trailing spaces"),
    mediaUnitName: Yup.string()
      .required("This field is required.")
      .trim("should not contain leading or trailing spaces"),
    mediaUnitEmail: Yup.string()
      .required("This field is required.")
      .email("Invalid email.")
      .trim("should not contain leading or trailing spaces"),

    MicName: Yup.string()
      .required("This field is required.")
      .trim("should not contain leading or trailing spaces"),

    MicContactNumber: Yup.string()
      .required("This field is required.")
      .trim("should not contain leading or trailing spaces"),

    PresidentName: Yup.string()
      .required("This field is required.")
      .trim("should not contain leading or trailing spaces"),
    presidentWhatsApp: Yup.string()
      .required("This field is required.")
      .trim("should not contain leading or trailing spaces"),
    PresidentEmail: Yup.string()
      .required("This field is required.")
      .email("Invalid email.")
      .trim("should not contain leading or trailing spaces"),
    inviteMethod: Yup.array()
      .min(1, "This field is required")
      .of(Yup.string().required())
      .required("This field is required"),
  });

  const RegistrationStartCounter = () => {
    return (
      <div className="Registrations__form__wrapper p-5 mt-5 w-100">
        <h2 className="Registrations__form__title  text-center">
          School registration
        </h2>
        <h5 className="text-light   text-center">
        On the request of the schools, the registration period for schools has been extended from June 26th at 3 p.m. to June 27th at 3 p.m.
        </h5>
        <div className="d-flex align-items-center justify-content-center w-100">
          <DayCouter
            counter={registrationOpenCountDown}
            counterTitle="Countdown to opening school registrations."
          />
        </div>
      </div>
    );
  };
  const RegistrationEnd = () => {
    return (
      <>
        <div className="Registrations__form__wrapper p-5 mt-5 w-100">
          <h2 className="Registrations__form__title  text-center">
            School registration
          </h2>
          <h5 className="text-light   text-center">
            School Registrations have been closed.
          </h5>
        </div>
        <div className="space w-100 p-5 m-5"></div>
      </>
    );
  };

  const formAction = {
    pending: RegistrationStartCounter,
    closed: RegistrationEnd,
  };
  const Ui = formAction[formActionStatus];
  return (
    <div className="container-fluid p-0 m-0 Registrations__wrapper">
      <Header theme="dark" />
      <div className="container">
        <div className="row m-0 p-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <img
              src={prabashwaraLogo}
              alt="prabashwaraLogo"
              className="Registrations__logo d-block mx-auto"
            />
            {formActionStatus === "open" ? (
              <div className="Registrations__form__wrapper p-5 mt-5 w-100">
                <h2 className="Registrations__form__title  text-center">
                  School registration
                </h2>
                <div className="d-flex align-items-center justify-content-center flex-wrap w-100 mb-3">
                  <div>
                    <h6>Note:</h6>
                    <ul>
                      <li>
                        On the request of the schools, the registration period for schools has been extended from June 26th at 3 p.m. to June 27th at 3 p.m.
                      </li>
                      <li>
                        Register at one school ONLY ONCE before registering
                        individual registrants.
                      </li>
                      <li>
                        Only the President of the Media Unit or a relevant
                        coordinator should fill out this form.
                      </li>
                      <li>
                        The information submitted in the form cannot be changed
                        again.
                      </li>
                    </ul>
                  </div>
                  <DayCouter
                    counter={registrationCloseCountDown}
                    counterTitle="Countdown to closing School Registrations."
                  />
                </div>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleFormSubmit}
                  validationSchema={validationSchema}
                >
                  <Form className="w-100 m-0 p-0">
                    <div className="inputWrapper">
                      <label>School name *</label>
                      <Input
                        name="schoolName"
                        type="text"
                        placeholder="Type here"
                        wrapperClasses="Registration__form__input"
                      />
                    </div>
                    <div className="inputWrapper">
                      <label>School address *</label>
                      <Input
                        name="schoolAddress"
                        type="text"
                        placeholder="Type here"
                        wrapperClasses="Registration__form__input"
                      />
                    </div>
                    <div className="inputWrapper">
                      <label>Media Unit name *</label>
                      <Input
                        name="mediaUnitName"
                        type="text"
                        placeholder="Type here"
                        wrapperClasses="Registration__form__input"
                      />
                    </div>
                    <div className="inputWrapper">
                      <label>Email address of Media Unit*</label>
                      <Input
                        name="mediaUnitEmail"
                        type="email"
                        placeholder="Type here"
                        wrapperClasses="Registration__form__input"
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="inputWrapper">
                          <label>Name of MIC Master in charge*</label>
                          <div className="input__group d-flex gap-3">
                            <Select
                              name="MicNamePrefix"
                              defaultSelect="Mr."
                              options={[
                                {
                                  name: "Mrs.",
                                  value: "Mrs.",
                                },
                                {
                                  name: "Master.",
                                  value: "Master.",
                                },
                                {
                                  name: "Miss.",
                                  value: "Miss.",
                                },
                              ]}
                            />
                            <div className="w-100">
                              <Input
                                name="MicName"
                                placeholder="Type here"
                                wrapperClasses="Registration__form__input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="inputWrapper">
                          <label>Contact number of MIC *</label>
                          <Input
                            name="MicContactNumber"
                            type="text"
                            placeholder="Type here"
                            wrapperClasses="Registration__form__input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="inputWrapper">
                          <label>Name of President or Coordinator*</label>
                          <div className="input__group d-flex gap-3">
                            <Select
                              name="PresidentNamePrefix"
                              defaultSelect="Mr."
                              options={[
                                {
                                  name: "Mrs.",
                                  value: "Mrs.",
                                },
                                {
                                  name: "Master.",
                                  value: "Master.",
                                },
                                {
                                  name: "Miss.",
                                  value: "Miss.",
                                },
                              ]}
                            />
                            <div className="w-100">
                              <Input
                                name="PresidentName"
                                placeholder="Type here"
                                wrapperClasses="Registration__form__input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="inputWrapper">
                          <label>WhatsApp number *</label>
                          <Input
                            name="presidentWhatsApp"
                            type="text"
                            placeholder="Type here"
                            wrapperClasses="Registration__form__input"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="inputWrapper">
                      <label>Email address of President or Coordinator*</label>
                      <Input
                        name="PresidentEmail"
                        type="email"
                        placeholder="Type here"
                        wrapperClasses="Registration__form__input"
                      />
                    </div>
                    <div className="inputWrapper">
                      <div className="input__group d-flex align-items-center gap-3">
                        <label>Send invitation of competition via:</label>
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="inputWrapper">
                            <label>Whatsapp</label>
                            <Input
                              name="inviteMethod"
                              type="checkbox"
                              wrapperClasses="input__checkbox__invitations__methods"
                              value="Whatsapp"
                            />
                          </div>

                          <div className="inputWrapper">
                            <label>Email</label>
                            <Input
                              name="inviteMethod"
                              type="checkbox"
                              wrapperClasses="input__checkbox__invitations__methods"
                              value="Email"
                            />
                          </div>

                          <div className="inputWrapper">
                            <label>Post</label>
                            <Input
                              name="inviteMethod"
                              type="checkbox"
                              wrapperClasses="input__checkbox__invitations__methods"
                              value="Post"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        setModelOpen(true);
                      }}
                      className="mt-4 w-100 submit"
                    >
                      Submit
                    </button>
                    {modelOpen ? (
                      <Model wrapperClassName="registrationPopup">
                        <h2>Are you sure ?</h2>
                        <p>
                          The information submitted in the form cannot be
                          changed again. So are you sure about the information
                          you entered ?
                        </p>
                        <div className="action__buttons__container w-100 d-flex">
                          <button
                            className="cancel"
                            onClick={() => {
                              setModelOpen(false);
                            }}
                          >
                            No, I need to check.
                          </button>
                          <button className="submit" type="submit">
                            Yes, I’m sure. Submit
                          </button>
                        </div>
                      </Model>
                    ) : null}
                  </Form>
                </Formik>
              </div>
            ) : (
              <Ui />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default SchoolRegistrations;
