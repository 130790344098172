import React, { useState } from "react";
import Header from "../../components/Header";
import "./Registrations.scss";
import { prabashwaraLogo } from "../../constants/asset";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

function Registrations() {
  return (

    <div className="container-fluid p-0 m-0 Registrations__wrapper">
      <Header theme="dark" />
      <div className="container">
        <div className="row m-0 p-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <img
              src={prabashwaraLogo}
              alt="prabashwaraLogo"
              className="Registrations__logo d-block mx-auto"
            />
            <div className="Registrations__form__wrapper p-5 mt-5 w-100">
              <h2 className="Registrations__form__title text-center">
                Prabhashwara Registration
              </h2>
              <h6 className="text-light">Note:</h6>
              <ul>
                <li>
                  Register at one school ONLY ONCE before registering individual
                  registrants.
                </li>
                <li>
                  Only the President of the Media Unit or a relevant coordinator
                  should fill out this school registration form.
                </li>
                <li>
                  The information submitted in the form cannot be changed again.
                  Please double check and fill the forms correctly.
                </li>
                <li>
                  Competitors must read all the rules and regulations for their
                  event before competing.
                </li>
              </ul>
              <div className="Registrations__form__links__wrapper">
                <Link
                  to="rulesandregulations"
                  target="_blank"
                >
                  Rules & regulations
                </Link>
                <Link to="school">School registration</Link>
                <Link to="individual">Individual registration</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    
  );
}

export default Registrations;
