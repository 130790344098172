import React, { useEffect, useState } from "react";
import "./Header.scss";
import { Link, useNavigate } from "react-router-dom";
import { logo, dashboardTitleImage } from "../../constants/asset";
function Header({ theme = "light" }) {
  const navigation = useNavigate();
  const [open, setOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className="primary__header" data-theme={theme}>
      <div className="Header__mobile w-100">
        <img
          src={theme === "light" ? logo : dashboardTitleImage}
          alt="Media Unite of Thurstan College"
          className="logo"
          onClick={() => navigation("/")}
        />

        {open ? (
          <i className="bx bx-x" onClick={() => setOpen(false)}></i>
        ) : (
          <i className="bx bx-menu-alt-left" onClick={() => setOpen(true)}></i>
        )}
      </div>
      <div className={`Header__navBar ${open ? "active" : ""}`}>
        <img
          src={theme === "light" ? logo : dashboardTitleImage}
          alt="Media Unite of Thurstan College"
          className="logo"
          onClick={() => navigation("/")}
        />
        {/* <ul className="primary__header__links">
          <li>
            <Link to="/">
              <span>Home</span>
            </Link>
          </li>
          <li>
            <Link to="/">
              <span>News</span> {screenWidth > 991 ? <br /> : null}
              <span>post</span>
            </Link>
          </li>
          <li>
            <Link to="/">
              <span>Event</span> {screenWidth > 991 ? <br /> : null}
              <span>Calendar</span>
            </Link>
          </li>
          <li>
            <Link to="/">
              <span>About</span> {screenWidth > 991 ? <br /> : null}
              <span>us</span>
            </Link>
          </li>
        </ul> */}
        <ul className="primary__header__social">
          <li>
            <Link to="https://www.facebook.com/TCMUofficial" target="_blank">
              <i className="bx bxl-facebook"></i>
            </Link>
          </li>
          <li>
            <Link to="https://www.instagram.com/tcmuofficial/" target="_blank">
              <i className="bx bxl-instagram"></i>
            </Link>
          </li>
          <li>
            <Link
              to="https://www.youtube.com/channel/UCn7--rZJ1oDkg1InCXNoyBg"
              target="_blank"
            >
              <i className="bx bxl-youtube"></i>
            </Link>
          </li>
          <li className="no-border">SOCIALS</li>
        </ul>
        <Link
          to="/prabhashwara-merchandise"
          className="registration__form__link"
        >
          <span>Prabhashwara Merchandise</span>
          <span>
            <i className="bx bx-arrow-back"></i>
          </span>
        </Link>
      </div>
    </div>
  );
}

export default Header;
