import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import enumValues from "../../../constants/enum";
import { toast } from "react-toastify";
import Select from "../../../components/Select";
import Input from "../../../components/Input/Input";
import idGen, { individualIdGen } from "../../../util/idGen";
import { getAllRecords, getSingleRecord } from "../../../hooks/useFetch";
import { saveDocument } from "../../../hooks/useSave";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import TeamMemberRegistration from "../../../components/Forms/TeamMemberRegistration";
import Model from "../../../components/Model";
import shortid from "shortid";
import { useNavigate } from "react-router-dom";
import "../Registrations.scss";
import { prabashwaraLogo } from "../../../constants/asset";
import dateDifferenceCounter from "../../../util/dateDifferenceCounter";
import DayCouter from "../../../components/DayCouter";

function IndividualRegistrations() {
  const [events, setEvents] = useState([]);
  const [schools, setSchools] = useState([]);
  const [ageCategories, setAgeCategories] = useState([]);
  const [mediumShow, setMediumShow] = useState(false);
  const [teamFormCountShow, setTeamFormCountShow] = useState(false);
  const [teamFormCount, setTeamFormCount] = useState(0);
  const [modelOpen, setModelOpen] = useState(false);
  const [formData, setFormData] = useState({
    school: "",
    ageCategory: "",
    medium: "",
    event: "",
    attended: false,
    teamForm: [
      {
        stdName: "",
        stdDOB: "",
        stdContactNumber: "",
        nic: "",
      },
    ],
  });
  const [formActionStatus, setFormActionStatus] = useState("pending");
  const [registrationOpenCountDown, setRegistrationOpenCountDown] = useState(
    {}
  );
  const [registrationCloseCountDown, setRegistrationCloseCountDown] = useState(
    {}
  );

  const navigate = useNavigate();
  const registrationOpenDate = "2024-06-26 13:00:00";
  const registrationCloseDate = "2024-08-15 23:59:59";

  useEffect(() => {
    //   FETCH EVENTS
    getAllRecords(enumValues.EVENT_COLLECTION)
      .then((data) => {
        setEvents(
          data.map((event) => {
            return { value: event._id, name: event.event };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
    //  FETCH SCHOOLS
    getAllRecords(enumValues.SCHOOL_REGISTRATIONS, "asc")
      .then((data) => {
        setSchools(
          data.map((school) => {
            return {
              value: school._id,
              name: `${school.id} / ${school.schoolName}`,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });

    const intlId = setInterval(() => {
      // CALCULATE DATE DIFFERENCE
      const registrationOpenDifference =
        dateDifferenceCounter(registrationOpenDate);
      const registrationCloseDifference = dateDifferenceCounter(
        registrationCloseDate
      );
      // SET STATE  DATE DIFFERENCE
      setRegistrationOpenCountDown(registrationOpenDifference);
      setRegistrationCloseCountDown(registrationCloseDifference);
      //  CHECK IF FORM IS OPEN
      const { days, hours, minutes, seconds } = registrationOpenDifference;
      if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        setFormActionStatus("open");
      } else {
        setFormActionStatus("pending");
      }
      //  CHECK IF FORM IS CLOSE
      const {
        days: daysClose,
        hours: hoursClose,
        minutes: minutesClose,
        seconds: secondsClose,
      } = registrationCloseDifference;
      if (
        daysClose === 0 &&
        hoursClose === 0 &&
        minutesClose === 0 &&
        secondsClose === 0
      ) {
        setFormActionStatus("closed");
      }
    }, 1000);
    return () => clearInterval(intlId);
  }, []);

  const handleEventChange = (value) => {
    if (value) {
      getSingleRecord(enumValues.EVENT_COLLECTION, value).then((data) => {
        setAgeCategories(
          data.age_category.map((age) => {
            return { value: age, name: age };
          })
        );
        setMediumShow(data.medium_show);
        if (data.event === "Short film") {
          setTeamFormCountShow(true);
        } else {
          setTeamFormCountShow(false);
          setTeamFormCount(0);
        }

        setFormData({
          ...formData,
          event: value,
        });
      });
    } else {
      setAgeCategories([]);
      setMediumShow(false);
      setTeamFormCountShow(false);
    }
  };

  // CUSTOM FORM HANDLERS
  const handleStdName = (value, index) => {
    const previousValues = [...formData.teamForm];
    previousValues[index].stdName = value;
    setFormData({
      ...formData,
      teamForm: previousValues,
    });
  };

  const handleStdDOB = (value, index) => {
    const previousValues = [...formData.teamForm];
    previousValues[index].stdDOB = value;
    setFormData({
      ...formData,
      teamForm: previousValues,
    });
  };

  const handleStdContactNumber = (value, index) => {
    const previousValues = [...formData.teamForm];
    previousValues[index].stdContactNumber = value;
    setFormData({
      ...formData,
      teamForm: previousValues,
    });
  };

  const nicShowDecider = () => {
    const dob = formData.teamForm[0].stdDOB;
    if (dob) {
      const birthYear = new Date(dob).getFullYear();
      return birthYear >= 2004 && birthYear <= 2007;
    }
    return false;
  };
  const handleStdNic = (value, index) => {
    const previousValues = [...formData.teamForm];
    previousValues[index].nic = value;
    setFormData({
      ...formData,
      teamForm: previousValues,
    });
  };

  const handleTeamFormCount = (value) => {
    let teamForm = [formData.teamForm[0]];
    setTeamFormCount(value);
    for (let i = 1; i < value; i++) {
      teamForm.push({
        stdName: "",
        stdDOB: "",
        stdContactNumber: "",
      });
    }
    setFormData({
      ...formData,
      teamForm,
    });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { school, ageCategory, teamForm, event } = formData;

    try {
      setModelOpen(false);
      //  VALIDATE FROM DATA
      if (!school || !event || !ageCategory) {
        toast.error("Please fill all the required fields");
        return;
      }
      const { stdName, stdDOB, stdContactNumber } = teamForm[0];
      if (!stdName || !stdDOB || !stdContactNumber) {
        toast.error("Please fill all the required fields");
        return;
      }

      if (teamFormCount > 0) {
        for (let i = 0; i < teamForm.length; i++) {
          if (
            !teamForm[i].stdName ||
            !teamForm[i].stdDOB ||
            !teamForm[i].stdContactNumber
          ) {
            toast.error("Please fill all the required fields");
            return;
          }
        }
      }
      if (nicShowDecider()) {
        if (!formData.teamForm[0].nic) {
          toast.error("Please fill all the required fields");
          return;
        }
      }

      //  GET SCHOOL ID

      const { id: schoolId } = await getSingleRecord(
        enumValues.SCHOOL_REGISTRATIONS,
        school
      );

      //  SAVE INDIVIDUAL STUDENT DATA
      const competitorId = await individualIdGen(
        enumValues.INDIVIDUAL_REGISTRATIONS,
        `${schoolId}/${enumValues.INDIVIDUAL_REGISTRATION_ID_PREFIX}`
      );
      console.log(competitorId);

      const { id: IndividualRegistrationId } = await saveDocument(
        enumValues.INDIVIDUAL_REGISTRATIONS,
        {
          ...formData,
          id: competitorId,
          _sysID: shortid.generate(),
        }
      );

      navigate(
        `/prabhashwara-registration/individual/success?individualId=${IndividualRegistrationId}`
      );
    } catch (error) {
      toast.error("Form submission failed");
      console.error(error);
    }
  };

  const RegistrationEnd = () => {
    return (
      <>
        <div className="Registrations__form__wrapper p-5 mt-5 w-100">
          <h2 className="Registrations__form__title  text-center">
            Individual registration
          </h2>
          <h5 className="text-light   text-center">
            Individual Registrations have been closed.
          </h5>
        </div>
        <div className="space w-100 p-5 m-5"></div>
      </>
    );
  };
  const RegistrationStartCounter = () => {
    return (
      <>
        <div className="Registrations__form__wrapper p-5 mt-5 w-100">
          <h2 className="Registrations__form__title  text-center">
            Individual registration
          </h2>
          <h5 className="text-light   text-center">
            Individual registrations will open on June 26 at 1 p.m.
          </h5>
          <div className="d-flex align-items-center justify-content-center w-100">
            <DayCouter
              counter={registrationOpenCountDown}
              counterTitle="Countdown to opening individual registrations."
            />
          </div>
        </div>
        <div className="space w-100 p-5 m-2"></div>
      </>
    );
  };
  const formAction = {
    pending: RegistrationStartCounter,
    closed: RegistrationEnd,
  };
  const Ui = formAction[formActionStatus];
  return (
    <div className="container-fluid p-0 m-0 Registrations__wrapper">
      <Header theme="dark" />
      <div className="container">
        <div className="row m-0 p-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <img
              src={prabashwaraLogo}
              alt="prabashwaraLogo"
              className="Registrations__logo d-block mx-auto"
            />
            {formActionStatus === "open" ? (
              <div className="Registrations__form__wrapper p-5 mt-5 w-100">
                <h2 className="Registrations__form__title  text-center">
                  Individual registration
                </h2>

                <div className="d-flex align-items-center justify-content-center flex-wrap w-100 mb-3">
                  <div>
                    <h6>Note:</h6>
                    <ul>
                      <li>
                        On the request of the contestants, the registration period for individual registration has been extended from July 03rd midnight to July 05th midnight.
                      </li>
                      <li>
                        Contestants can only register at schools that have
                        completed the school registration process.
                      </li>
                      <li>
                        The information submitted in the form cannot be changed
                        again.
                      </li>
                      <li>
                        If you have any problems with registration, feel free to call or WhatsApp the following numbers:<br />
                        Kavindu: <a href="https://wa.me/+94786294038" target="_blank">+94786294038</a><br />
                        Pahan: <a href="https://wa.me/+94740124700" target="_blank">+94740124700</a><br />
                        Tharusha: <a href="https://wa.me/+94769320733" target="_blank">+94769320733</a>
                      </li>
                    </ul>
                  </div>
                  <DayCouter
                    counter={registrationCloseCountDown}
                    counterTitle="Countdown to closing School Registrations."
                  />
                </div>
                <Formik>
                  <Form className="w-100 p-0 m-0">
                    <div className="inputWrapper">
                      <label>Contestant's Name *</label>
                      <Input
                        name="stdName"
                        placeholder="Type here"
                        wrapperClasses="Registration__form__input"
                        onChange={(value) => handleStdName(value, 0)}
                      />
                    </div>
                    <div className="inputWrapper">
                      <label>
                        Name of School*
                        <br />
                        (Only schools registered through school registration are
                        shown here. If your school is not showing then you need
                        to register the school.)
                      </label>
                      <Select
                        name="school"
                        defaultSelect="Select a school"
                        options={schools}
                        onChange={(e) =>
                          setFormData({ ...formData, school: e.target.value })
                        }
                      />
                    </div>
                    <div className="row m-0 p-0">
                      <div className="col-lg-12 col-md-12 col-sm-12 p-0 columnInputWrapper mt-3">
                        <div className="inputWrapper">
                          <label>Date of Birthday *</label>
                          <Input
                            name="stdDOB"
                            type="date"
                            min={`${new Date().getFullYear() - 20}-01-01`}
                            max={`${new Date().getFullYear() - 11}-12-31`}
                            placeholder="Type here"
                            wrapperClasses="Registration__form__input"
                            onChange={(value) => handleStdDOB(value, 0)}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 p-0 columnInputWrapper">
                        <div className="inputWrapper">
                          <label>Contact number *</label>
                          <Input
                            name="stdContactNumber"
                            type="text"
                            placeholder="Type here"
                            wrapperClasses="Registration__form__input"
                            onChange={(value) =>
                              handleStdContactNumber(value, 0)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {nicShowDecider() ? (
                      <div className="row m-0 p-0">
                        <div className="col-lg-12 col-md-12 col-sm-12 p-0 columnInputWrapper">
                          <div className="inputWrapper">
                            <label>Student NIC * </label>
                            <Input
                              name="stdNic"
                              type="text"
                              placeholder="Type here"
                              wrapperClasses="Registration__form__input"
                              onChange={(value) => handleStdNic(value, 0)}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="row mt-5 p-0">
                      <div className="col-lg-4 col-md-12 col-sm-12 columnInputWrapper">
                        <div className="inputWrapper p-2">
                          <label>Event *</label>
                          <Select
                            name="event"
                            defaultSelect="Select a event"
                            options={events}
                            onChange={(e) => handleEventChange(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12 columnInputWrapper">
                        <div className="inputWrapper p-2">
                          <label>Age category *</label>
                          <Select
                            name="agecategory"
                            defaultSelect="Select age category"
                            disable={ageCategories.length === 0}
                            options={ageCategories}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                ageCategory: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12 columnInputWrapper">
                        <div className="inputWrapper p-2">
                          <label>Medium *</label>
                          <Select
                            name="medium"
                            defaultSelect="Select Medium"
                            disable={!mediumShow}
                            options={[
                              {
                                value: "SM",
                                name: "Sinhala Medium",
                              },
                              {
                                value: "EM",
                                name: "English Medium",
                              },
                            ]}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                medium: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {teamFormCountShow ? (
                      <div className="row my-3 p-0">
                        <div className="col-lg-4 col-md-12 col-sm-12 columnInputWrapper">
                          <div className="inputWrapper p-2">
                            <label>Number of team members *</label>
                            <Select
                              name="noOfTeamMembers"
                              defaultSelect="1"
                              options={[
                                {
                                  value: "2",
                                  name: "2",
                                },
                                {
                                  value: "3",
                                  name: "3",
                                },
                                {
                                  value: "4",
                                  name: "4",
                                },
                                {
                                  value: "5",
                                  name: "5",
                                },
                                {
                                  value: "6",
                                  name: "6",
                                },
                                {
                                  value: "7",
                                  name: "7",
                                },
                              ]}
                              onChange={(e) =>
                                handleTeamFormCount(e.target.value)
                              }
                              value={teamFormCount}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {teamFormCount > 0 ? (
                      <>
                        {Array.from(
                          { length: teamFormCount },
                          (_, i) => i + 1
                        ).map((i) => (
                          <TeamMemberRegistration
                            key={i}
                            teamCount={i}
                            stdNameInputName={
                              i === 1 ? "stdName" : `stdName-${i}`
                            }
                            stdDOBInputName={i === 1 ? "stdDOB" : `stdDOB-${i}`}
                            stdContactNumber={
                              i === 1
                                ? "stdContactNumber"
                                : `stdContactNumber-${i}`
                            }
                            stdNameInputValue={formData.teamForm[i - 1].stdName}
                            stdDOBInputValue={formData.teamForm[i - 1].stdDOB}
                            stdContactValue={
                              formData.teamForm[i - 1].stdContactNumber
                            }
                            handleStdNameInput={handleStdName}
                            handleStdDOBInput={handleStdDOB}
                            handleStdContactNumber={handleStdContactNumber}
                            disabled={i === 1}
                          />
                        ))}
                      </>
                    ) : null}
                    <button
                      type="button"
                      onClick={() => {
                        setModelOpen(true);
                      }}
                      className="mt-4 w-100 submit"
                    >
                      Submit
                    </button>
                    {modelOpen ? (
                      <Model wrapperClassName="registrationPopup">
                        <h2>Are you sure ?</h2>
                        <p>
                          The information submitted in the form cannot be
                          changed again. So are you sure about the information
                          you entered ?
                        </p>
                        <div className="action__buttons__container w-100 d-flex">
                          <button
                            className="cancel"
                            onClick={() => {
                              setModelOpen(false);
                            }}
                          >
                            No, I need to check.
                          </button>
                          <button
                            className="submit "
                            type="submit"
                            onClick={handleFormSubmit}
                          >
                            Yes, I’m sure. Submit
                          </button>
                        </div>
                      </Model>
                    ) : null}
                  </Form>
                </Formik>
              </div>
            ) : (
              <Ui />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default IndividualRegistrations;
