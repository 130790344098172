export default function dateDifferenceCounter(targetDate) {
  const today = new Date();
  const tgDate = new Date(targetDate);
  // tgDate.setHours(0, 0, 0, 0);
  const difference = tgDate - today;
  if (difference <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  } else {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
}
