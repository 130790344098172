import React, { useEffect, useState } from "react";
import "./IndividualRegistrationSuccess.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Select from "../../components/Select";

import { prabashwaraLogo } from "../../constants/asset";
import ClipBoardCopy from "../../components/ClipBoardCopy/ClipBoardCopy";
import { Formik, Form } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getSingleRecord } from "../../hooks/useFetch";
import enumValues from "../../constants/enum";
import QRCode from "qrcode.react";

/**
 * Renders the IndividualRegistrationSuccess component.
 *
 * @return {JSX.Element} The rendered component.
 */

function IndividualRegistrationSuccess() {
  const [language, setLanguage] = useState("eng");
  const { search } = useLocation();
  const navigate = useNavigate();
  const [event, setEvent] = useState("");
  const [student, setStudent] = useState({});
  const [competitorId, setCompetitorId] = useState("");
  const [individualRegistrationsId, setIndividualRegistrationsId] =
    useState("");
  useEffect(() => {
    const individualId = new URLSearchParams(search).get("individualId");
    if (individualId) {
      getSingleRecord(enumValues.INDIVIDUAL_REGISTRATIONS, individualId)
        .then((data) => {
          if (data?.event) {
            setIndividualRegistrationsId(data?._id);
            setCompetitorId(data?.id);
            setStudent(data?.teamForm?.[0]);
            getSingleRecord(enumValues.EVENT_COLLECTION, data?.event).then(
              (eventData) => {
                setEvent(eventData?.event);
              }
            );
          } else {
            navigate("/prabhashwara-registration/individual/", {
              replace: true,
            });
          }
        })
        .catch(() => {
          navigate("/prabhashwara-registration/individual/", { replace: true });
        });
    } else {
      navigate("/prabhashwara-registration/individual/", { replace: true });
    }
  }, [search]);

  const QRDownload = () => {
    const qrCodeURL = document
      .getElementById("STDQrCode")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let link = document.createElement("a");
    link.download = `${student?.stdName}-${competitorId}.png`;
    link.href = qrCodeURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container-fluid p-0 m-0 Registrations__wrapper">
      <Header theme="dark" />
      <div className="container">
        <div className="row m-0 p-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <img
              src={prabashwaraLogo}
              alt="prabashwaraLogo"
              className="Registrations__logo d-block mx-auto"
            />
            <div className="Registrations__form__wrapper p-5 mt-5 w-100">
              <h2 className="Registrations__form__title text-center">
                Form submitted.
              </h2>
              <h5 className=" SchoolRegistrationSuccess__school">
                Contestant Name: {student?.stdName}
              </h5>
              <h5 className="SchoolRegistrationSuccess__school__text">
                The code issued for you {event}
              </h5>
              <ClipBoardCopy textToCopy={competitorId} wrapperClass="mt-5" />
              <h5 className="SchoolRegistrationSuccess__school__text qr">
                The QR code issued for you
              </h5>
              <div className="SchoolRegistrationSuccess__terms IndividualRegistrationSuccess__terms d-flex flex-wrap gap-3">
                <div className="p-3 bg-light">
                  <QRCode
                    value={individualRegistrationsId}
                    size={200}
                    style={{ height: "auto", maxWidth: "200px", width: "100%" }}
                    onClick={QRDownload}
                    id="STDQrCode"
                  />
                </div>
                <h5 className="mt-5">
                  Click on the QR code to download.<br />
                  QR කේතය මත ක්ලික් කර බාහත කරගන්න.
                </h5>
              </div>
              <div className="SchoolRegistrationSuccess__terms IndividualRegistrationSuccess__terms">
                <div className="SchoolRegistrationSuccess__terms__headings">
                  {language === "eng" ? (
                    <h3>Please read this before leaving:</h3>
                  ) : (
                    <h3>පිටව යාමට පෙර කියවන්න</h3>
                  )}
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <span>{language === "eng" ? "Language: " : "භාශාව: "}</span>
                    <Formik>
                      <Form>
                        <Select
                          name="language"
                          options={[
                            { value: "eng", name: "English" },
                            { value: "sin", name: "සිංහල" },
                          ]}
                          onChange={(e) => setLanguage(e.target.value)}
                          wrapperClasses="SchoolRegistrationSuccess__terms__select"
                        />
                      </Form>
                    </Formik>
                  </div>
                </div>
                {language === "eng" ? (
                  <ul>
                    <li>We have recorded all the data you entered.</li>
                    <li>
                      Correctly keep the code ana QR code that was given to you.
                      Bring that codes on the competition day.
                    </li>
                    <li>
                      Competitors must read all the rules and regulations for
                      their event before competing.{" "}
                      <Link
                        to="../prabhashwara-registration/rulesandregulations"
                        target="_blank"
                        className="IndividualRegistrationSuccess__terms__link"
                      >
                        Click here
                      </Link>{" "}
                      to read rules and regulations.
                    </li>
                    <li>
                      By participating in the competition, you are competing in
                      accordance with the Competition Event Rules and
                      Regulations.
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>ඔබ විසින් ඇතුළත් කල සියලුම දත්ත වර්තා කර ඇත.</li>
                    <li>
                      ඔබට ලබා දී ඇති කේතය සහ QR කේතය නිවැරදිව ළඟ තබා ගන්න. එම
                      කේත තරඟාවලිය පැවැත්වෙන දිනට රැගෙන එන්න.
                    </li>
                    <li>
                      තරඟකරුවන් තරඟ කිරීමට පෙර ඔවුන්ගේ ඉසව් සඳහා සියලුම නීති
                      රීති කියවිය යුතුය. නීති සහ රෙගුලාසි කියවීමට{" "}
                      <Link
                        to="../prabhashwara-registration/rulesandregulations"
                        target="_blank"
                        className="IndividualRegistrationSuccess__terms__link"
                      >
                        මෙතන ක්ලික්
                      </Link>{" "}
                      {""}
                      කරන්න.
                    </li>
                    <li>
                      තරඟයට සහභාගි වීමෙන්, ඔබ තරඟ රීති සහ රෙගුලාසි වලට අනුකූලව
                      තරඟ කරයි.
                    </li>
                  </ul>
                )}
                {event === enumValues.EVENT_SHORT_FILM ||
                event === enumValues.EVENT_GRAPHIC_DESIGNING ||
                event === enumValues.EVENT_PHOTOGRAPHY ? null : (
                  <Link
                    to="/prabhashwara-registration/individual"
                    className="text-dark"
                  >
                    Submit another contestant
                  </Link>
                )}
              </div>
              {event === enumValues.EVENT_SHORT_FILM ||
              event === enumValues.EVENT_PHOTOGRAPHY ||
              event === enumValues.EVENT_GRAPHIC_DESIGNING ? (
                <>
                  <div className="SchoolRegistrationSuccess__terms IndividualRegistrationSuccess__terms">
                    <div className="SchoolRegistrationSuccess__terms__headings">
                      <h3 className="text-light">
                        How to submit your design or video
                      </h3>
                    </div>
                    <ul>
                      <li>
                        Enter your design or video in the form provided by the
                        link below, depending on the competition category in
                        which you have registered.
                      </li>
                      <li>
                        Remember to keep the link until you finish your design
                        or video work.
                      </li>
                    </ul>
                  </div>
                  <ClipBoardCopy
                    textToCopy={`${window.location.protocol}//${
                      window.location.host
                    }${
                      enumValues.PROJECT_PUBLISHING_URL
                    }?individualId=${new URLSearchParams(search).get(
                      "individualId"
                    )}`}
                    wrapperClass="mt-5"
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default IndividualRegistrationSuccess;
