import React, { useEffect, useState } from "react";
import "./SchoolRegistrationSuccess.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Select from "../../components/Select";
import { Form, Formik } from "formik";
import { prabashwaraLogo } from "../../constants/asset";
import { Link, useNavigate, useParams } from "react-router-dom";
import { findByDocumentKey } from "../../hooks/useFetch";
import enumValues from "../../constants/enum";
import ClipBoardCopy from "../../components/ClipBoardCopy/ClipBoardCopy";


function SchoolRegistrationSuccess() {
  const [textToCopy, setTextToCopy] = useState("PB0001");
  const [schoolName, setSchoolName] = useState("");
  const [language, setLanguage] = useState("eng");

  const { schId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    findByDocumentKey(enumValues.SCHOOL_REGISTRATIONS, "id", schId)
      .then((data) => {
        if (data) {
          setTextToCopy(schId);
          setSchoolName(data?.schoolName);
        }
      })
      .catch(() => {
        navigate("/prabhashwara-registration/school");
      });
  }, [schId]);

  const preferredLanguage = {
    eng: {
      title: "Please read this before leaving:",
      terms: [
        "Your school registration is complete.",
        "Correctly write down the code that was given to your school.",
        "Contestants must register separately, and to do so, please click the button below.",
      ],
    },
    sin: {
      title: "පිටව යාමට පෙර කියවන්න",
      terms: [
        "ඔබේ පාසල ලියාපදිංචිය සම්පූරණයි.",
        "ඔබේ පාසලට ලබා දී ඇති කේතය නිවැරදිව ලියා තබා ගන්න.",
        "තරඟකරුවන් වෙන වෙනම ලියාපදිංචි විය යුතු අතර, එසේ කිරීමට කරුණාකර පහත බොත්තම ක්ලික් කරන්න.",
      ],
    },
  };
  return (

    <div className="container-fluid p-0 m-0 Registrations__wrapper">
      <Header theme="dark" />
      <div className="container">
        <div className="row m-0 p-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <img
              src={prabashwaraLogo}
              alt="prabashwaraLogo"
              className="Registrations__logo d-block mx-auto"
            />
            <div className="Registrations__form__wrapper p-5 mt-5 w-100">
              <h2 className="Registrations__form__title text-center">
                Form submitted.
              </h2>
              <h5 className=" SchoolRegistrationSuccess__school">
                School Name: {schoolName}
              </h5>
              <h5 className="SchoolRegistrationSuccess__school__text">
                The code issued for the school
              </h5>

              <ClipBoardCopy textToCopy={textToCopy} wrapperClass="mt-5" />
              <div className="SchoolRegistrationSuccess__terms">
                <div className="SchoolRegistrationSuccess__terms__headings">
                  <h3>{preferredLanguage[language].title}</h3>
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <span>{language === "eng" ? "Language: " : "භාශාව: "}</span>
                    <Formik>
                      <Form>
                        <Select
                          name="language"
                          options={[
                            { value: "eng", name: "English" },
                            { value: "sin", name: "සිංහල" },
                          ]}
                          onChange={(e) => setLanguage(e.target.value)}
                          wrapperClasses="SchoolRegistrationSuccess__terms__select"
                        />
                      </Form>
                    </Formik>
                  </div>
                </div>
                <ul>
                  {preferredLanguage[language].terms.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <Link to="/prabhashwara-registration/individual" className="text-dark">
                  Submit individual registrations
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    
  );
}

export default SchoolRegistrationSuccess;
