import React from "react";
import "./DayCouter.scss";

/**
 * Renders a DayCouter component.
 *
 * @param {Object} props - The props object.
 * @param {number} props.counter - The counter value.
 * @param {string} props.counterTitle - The title of the counter.
 * @return {JSX.Element} The rendered DayCouter component.
 */
function DayCouter({ counter, counterTitle }) {
  const strFormatter = (num) => {
    if (num) {
      return num.toString().padStart(2, "0");
    } else {
      return "00";
    }
  };
  return (
    <div className="DayCouter__wrapper p-2">
      <h5 className="DayCouter__counter__title">{counterTitle}</h5>
      <div className="DayCouter__counter__wrapper">
        <div className="couter__card">
          <span>{strFormatter(counter.days)}</span>
          <span>Days</span>
        </div>
        <span>:</span>
        <div className="couter__card">
          <span>{strFormatter(counter.hours)}</span>
          <span>Hours</span>
        </div>
        <span>:</span>
        <div className="couter__card">
          <span>{strFormatter(counter.minutes)}</span>
          <span>Minutes</span>
        </div>
        <span>:</span>
        <div className="couter__card">
          <span>{strFormatter(counter.seconds)}</span>
          <span>Seconds</span>
        </div>
      </div>
    </div>
  );
}

export default DayCouter;
