import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Model from "../../../components/Model/Model";
import { prabashwaraLogo } from "../../../constants/asset";
import { Form, Formik } from "formik";
import Input from "../../../components/Input";
import TextArea from "../../../components/TextArea";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { getSingleRecord } from "../../../hooks/useFetch";
import enumValues from "../../../constants/enum";
import { updateDocument } from "../../../hooks/useSave";
import { toast } from "react-toastify";
// import PreloaderPB from "../../../components/PreloaderPB/preloaderpb";

function ProjectSubmission() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [IndividualData, setIndividualData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);

  const initialValues = {
    project: "",
    description: "",
    softwares: "",
    projectLink: "",
  };
  const validationSchema = Yup.object({
    project: Yup.string().required("Project title is required"),
    softwares: Yup.string().required("Used softwares is required"),
    projectLink: Yup.string()
      .url("Please enter a valid URL")
      .required("Project link is required"),
  });

  useEffect(() => {
    const fetData = async () => {
      if (search) {
        const individualId = new URLSearchParams(search).get("individualId");
        if (individualId) {
          const data = await getSingleRecord(
            enumValues.INDIVIDUAL_REGISTRATIONS,
            individualId
          );
          if (!data._sysID) return navigate("/prabhashwara-registration");
          let finalizedDataObj = { ...data };
          //   PUBLISH EVENT DATA
          const event = await getSingleRecord(
            enumValues.EVENT_COLLECTION,
            data.event
          );
          finalizedDataObj.event = event;
          //   PUBLISH SHOOL DATA
          const school = await getSingleRecord(
            enumValues.SCHOOL_REGISTRATIONS,
            data.school
          );
          finalizedDataObj.school = school;
          if (finalizedDataObj.projectData) {
            setFormSubmitted(true);
          }
          setIndividualData(finalizedDataObj);
        } else {
          return navigate("/prabhashwara-registration");
        }
      } else {
        return navigate("/prabhashwara-registration");
      }
    };
    fetData();
  }, []);

  const handleSubmit = async (values, formikActions) => {
    try {
      // Fetch Individual Data
      const individualId = new URLSearchParams(search).get("individualId");
      let individualData = await getSingleRecord(
        enumValues.INDIVIDUAL_REGISTRATIONS,
        individualId
      );
      individualData.projectData = values;
      await updateDocument(
        enumValues.INDIVIDUAL_REGISTRATIONS,
        individualId,
        individualData
      );
      toast.success("Form submitted successfully");
      setFormSubmitted(true);
    } catch (error) {
      toast.error("Something went wrong");
    }
    formikActions.resetForm();
  };
  return (
    <div className="container-fluid p-0 m-0 Registrations__wrapper">
      <Header theme="dark" />
      <div className="container">
        <div className="row m-0 p-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <img
              src={prabashwaraLogo}
              alt="prabashwaraLogo"
              className="Registrations__logo d-block mx-auto"
            />
            <div className="Registrations__form__wrapper p-5 mt-5 w-100">
              <h2 className="Registrations__form__title  text-center">
                Design and video submit form
              </h2>
              <table className="mb-5">
                <tbody>
                  <tr className="h-auto">
                    <td className="text-edit">Name(s) :</td>
                    <td className="text-edit">
                      {IndividualData?.teamForm?.map((data, index) => (
                        <span className="d-block mx-1" key={index}>
                          {data.stdName}{" "}
                        </span>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>ID</td>
                    <td>: {IndividualData?.id}</td>
                  </tr>
                  <tr>
                    <td>School</td>
                    <td>: {IndividualData?.school?.schoolName}</td>
                  </tr>
                  <tr>
                    <td>Event</td>
                    <td>: {IndividualData?.event?.event}</td>
                  </tr>
                </tbody>
              </table>
              {!formSubmitted ? (
                <>
                  <h6 className="text-light">Note :</h6>
                  <ul>
                    <li>Submit the following fields correctly. </li>
                    <li>
                      Firstly, upload your design or video to a cloud storage
                      platform (ex: Google Drive or One Drive) and set the
                      access permission to "Anyone with the link." Next, copy
                      and paste the link in the relevant field.
                    </li>
                    <li>
                      The information submitted in the form cannot be changed
                      again. Please double check and fill the forms correctly.
                    </li>
                  </ul>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form className="w-100 p-0 m-0">
                      <div className="inputWrapper">
                        <label>Design or video title*</label>
                        <Input
                          name="project"
                          placeholder="Type here"
                          wrapperClasses="Registration__form__input"
                        />
                      </div>
                      <div className="inputWrapper">
                        <label>Description (Optional)</label>
                        <TextArea
                          name="description"
                          wrapperClasses="Registration__form__input textarea"
                        />
                      </div>
                      <div className="inputWrapper">
                        <label>
                          Used softwares* <br />{" "}
                          <small>Ex: Photoshop, Illustrator</small>
                        </label>
                        <Input
                          name="softwares"
                          placeholder="Type here"
                          wrapperClasses="Registration__form__input"
                        />
                      </div>
                      <div className="inputWrapper">
                        <label>
                          Project Link* <br />{" "}
                          <small>
                            (Upload your design or video to the cloud storage
                            platform (ex: Google Drive, One Drive) and make
                            settings to access permission “Anyone with the
                            link." Next, copy and paste the link here. )
                          </small>
                        </label>
                        <Input
                          name="projectLink"
                          placeholder="Type here"
                          wrapperClasses="Registration__form__input"
                        />
                      </div>
                      <button
                        className="mt-4 w-100 submit"
                        type="button"
                        onClick={() => {
                          setModelOpen(true);
                        }}
                      >
                        Submit
                      </button>
                      {modelOpen ? (
                        <Model wrapperClassName="registrationPopup">
                          <h2>Are you sure ?</h2>
                          <p>
                            The information submitted in the form cannot be
                            changed again. So are you sure about the information
                            you entered ?
                          </p>
                          <div className="action__buttons__container w-100 d-flex">
                            <button
                              className="cancel"
                              onClick={() => {
                                setModelOpen(false);
                              }}
                            >
                              No, I need to check.
                            </button>
                            <button className="submit" type="submit">
                              Yes, I’m sure. Submit
                            </button>
                          </div>
                        </Model>
                      ) : null}
                    </Form>
                  </Formik>
                </>
              ) : (
                <div className="formSubmitted__area">
                  <h3 className="text-center">Form submitted.</h3>
                  <p className="text-center">
                    If you have any questions about submitting your design or
                    video, please contact us.
                    <br />
                    <br />
                    Kavindu Ranthisaru : +94 78 629 4038
                    <br />
                    Tharusha Gunawardhana :  +94 76 932 0733
                    <br />
                    Pahan Weerasuriya : +94 74 012 4700
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ProjectSubmission;
